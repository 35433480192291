<!--
 * @Author: YangChao
 * @Date: 2022/2/15 14:48
 * @Description: file content
 -->
<template>
  <div class="download-warp">
    <!--    <img src="@/assets/image/appIcon.png" alt="app icon" class="app-icon">-->
    <!--    <p class="app-name">企业悠订App</p>-->
    <!--    <div class="app-info">悠订系统，为餐饮企业提供业务数字化一站式服务</div>-->
    <!--    <div @click="download" class="download-button">免费下载企业悠订App</div>-->
  </div>
</template>

<script>
import { isIos } from '@/utils/whatApp'

export default {
  name: 'DownloadApp',
  mounted() {
    document.querySelector('html').style['min-width'] = '100%'
    document.querySelector('body').style['min-width'] = '100%'
  },
  created() {
    this.download()
  },
  methods: {
    download() {
      if (isIos()) {
        window.location.href = 'https://apps.apple.com/cn/app/qi-ye-you-ding/id1569525162'
        return false
      }
      window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.wh.b'
    }
  }
}
</script>

<style lang="less" scoped>
.download-warp {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .app-icon {
    display: block;
    width: 120px;
    height: 120px;
    margin: 30% auto 0;
  }

  .app-name {
    font-size: 16px;
    color: @color-text-main;
    text-align: center;
    font-weight: 650;
  }

  .app-info {
    font-size: 14px;
    color: @color-text-normal;
    text-align: center;
    font-weight: 650;
  }

  .download-button {
    margin: 0 auto;
  }

  .download-button {
    font-size: 14px;
    height: 36px;
    background: @color-primary;
    color: @color-white;
    line-height: 36px;
    border-radius: 4px;
    padding: 0 30px;
    margin-top: 50px;
  }
}
</style>
