// 是否是安卓
export function isAndroid() {
  const u = navigator.userAgent
  // 这个是安卓操作系统
  return u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
}

export function isIos() {
  const u = navigator.userAgent
  // 这个是ios操作系统
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}
